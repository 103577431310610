import { axiosInstance } from "../store";
import Constants from '../constants';
import { handlStatusCodeMessage } from "../helpers";

export function fetchItemsCount(): Promise<number> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/item`)
        .then((res) => res.headers['x-total-count'])
        .catch((err) => {
            handlStatusCodeMessage({code: err.response.status, entity: 'dashboard'});
            return 0
        })
}

export function fetchProductsCount(page = 0, name = ''): Promise<number> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/product_balance?page=${page}&name=${name}`)
        .then((res) => res.headers['x-total-count'])
        .catch((err) => {
            handlStatusCodeMessage({code: err.response.status, entity: 'dashboard'});
            return 0
        })
}

export function fetchWarningsCount(): Promise<number> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/product_balance?status=warning`)
        .then((res) => res.headers['x-total-count'])
        .catch((err) => {
            handlStatusCodeMessage({code: err.response.status, entity: 'dashboard'});
            return 0
        })
}

export function fetchCriticalsCount(): Promise<number> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/product_balance?status=critical`)
        .then((res) => res.headers['x-total-count'])
        .catch((err) => {
            handlStatusCodeMessage({code: err.response.status, entity: 'dashboard'});
            return 0
        })
}

export function fetchDataTableLastTransactions(page = 0, size = 10, sort = 'createdAt,desc'): Promise<{ lastTransactions: any[], totalCountPages: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/product/transactions?page=${page}&size=${size}&sort=${sort}`)
        .then((res) => {
            return {
                lastTransactions: res.status === 200 ? res.data : [],
                totalCountPages: res.headers['x-total-count']
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({code: err.response.status, entity: 'dashboard'});
            return {
                lastTransactions: [],
                totalCountPages: 0
            }
        })
}

export function fetchInboundOutbound(from = '', to = '', type = ''): Promise<{ transactions: any[] }> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/request/dashboard?from=${from}&to=${to}&type=${type}`)
        .then((res) => {
            return {
                transactions: res.status === 200 ? res.data: [],
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({code: err.response.status, entity: 'dashboard'});
            return {
                transactions: [],
            }
        })
}

export function fetchLastOrdersData(page = 0, size = 10, sort = '', status = '', isVendor = false): Promise<{ orders: any[] }> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/order?page=${page}&size=${size}&sort=${sort}&status=${status}&is_vendor=${isVendor}`)
        .then((res) => {
            return {
                orders: res.status === 200 ? res.data: [],
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({code: err.response.status, entity: 'dashboard'});
            return {
                orders: [],
            }
        })
}

export function fetchMostActiveUsers(): Promise<{ users: any[] }> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/product/active-users`)
        .then((res) => {
            return {
                users: res.status === 200 ? res.data: [],
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({code: err.response.status, entity: 'dashboard'});
            return {
                users: [],
            }
        })
}

export function fetchMostActiveProducts(page = 0, size = 10): Promise<{ activeProducts: any[] }> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/product/active-products?page=${page}&size=${size}`)
        .then((res) => {
            return {
                activeProducts: res.status === 200 ? res.data: [],
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({code: err.response.status, entity: 'dashboard'});
            return {
                activeProducts: [],
            }
        })
}