import { axiosInstance } from "../store";
import IOperator from "../dtos/IOperator";
import IStatus from "../dtos/IStatus";
import { handlStatusCodeMessage } from "../helpers";

export function fetchOperators(page = 0, size = 10, sort = '', name = '', status = ''): Promise<{ operators: IOperator[], totalOperatorsCount: 0 }> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/operator?page=${page}&size=${size}&sort=${sort}&name=${name}&status=${status}`)
        .then((res) => {
            if (res.status == 200) {
                return {
                    operators: res.data,
                    totalOperatorsCount: res.headers['x-total-count']
                }
            }
            else {
                return {
                    operators: [],
                    totalOperatorsCount: 0
                }
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'operator' });
            return {
                operators: [],
                totalOperatorsCount: 0
            }
        })
}
export function fetchOperator(operatorId: number): Promise<IOperator> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/operator/${operatorId}`)
        .then((res) => {
            if (res.status == 200) {
                return res.data
            }
            else {
                return {}
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'operator' });
            return {}
        })
}
export function fetchOperatorStatus(): Promise<IStatus[]> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/lookup/operator_status`)
        .then((res) => {
            if (res.status == 200) {
                return res.data
            }
            else {
                return []
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'operator' });
            return []
        })
}
export function addOperator(operator: IOperator): Promise<boolean> {
    return axiosInstance.post(`${process.env.REACT_APP_SMART_STORAGE_URI}/operator`, operator)
        .then((res) => {
            handlStatusCodeMessage({ code: res.status, entity: 'operator' });
            return res.status == 200;
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'operator' });
            return false
        })

}

export function updateOperator(operatorId: number, operator: IOperator): Promise<boolean> {
    return axiosInstance.put(`${process.env.REACT_APP_SMART_STORAGE_URI}/operator/${operatorId}`, operator)
        .then((res) => {
            handlStatusCodeMessage({ code: res.status, entity: 'operator' });
            return res.status == 200;
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'operator' });
            return false
        })

}