import { axiosInstance } from "../store";
import IUser from "../dtos/IUser";
import IBranch from "../dtos/IBranch";
import { handlStatusCodeMessage } from "../helpers";
import axios from "axios";

export function fetchUsers(
    page = 0,
    size = 10,
    sort = "",
    name = ""
): Promise<{ users?: IUser[]; totalUsersCount?: number }> {
    const service_account_id = localStorage.getItem("service_account_id");
    return axiosInstance
        .get(
            `${process.env.REACT_APP_ACCOUNT_SERVICE_URI
            }/user?first_name&last_name&component_id=1&tenant_id=${service_account_id}&page=${page}&size=${size}&sort=${sort}${name ? `&name=${name}` : ""
            }`
        )
        .then((res) =>
            res.status == 200
                ? {
                    users: res.data,
                    totalUsersCount: res.headers["x-total-count"],
                }
                : {
                    users: [],
                    totalUsersCount: 0,
                }
        )
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return {
                users: [],
                totalCountPages: 0,
            };
        });
}

export function validateUser(
    user: any
): Promise<{
    userValidated: boolean;
    service_account_id: number | null;
    my_branches: IBranch[] | null;
    user_id: number | null;
}> {
    return axiosInstance
        .get(
            `${process.env.REACT_APP_ACCOUNT_SERVICE_URI}/user/sign-in?email=${user.idTokenParsed.email}&subject_id=${user.subject}&component_id=1&storefront_id=1`
        )
        .then(async (res) => {
            if (res.status == 200) {
                localStorage.setItem("branches_comma_separated", res.data.extra);
                const branches = await axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/branch?branches_ids=${res.data.extra}&service_account_id=${res.data.user_profile.tenant.id}`)
                return {
                    userValidated: true,
                    service_account_id: res.data.user_profile.tenant.id,
                    my_branches: branches.data,
                    user_permissions: res.data.user_permissions,
                    user_id: res.data.user_info.user_id,
                };
            } else {
                return {
                    userValidated: false,
                    service_account_id: null,
                    my_branches: [],
                    user_permissions: [],
                    user_id: null,
                };
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return {
                userValidated: false,
                service_account_id: null,
                my_branches: [],
                user_permissions: [],
                user_id: null,
            };
        });
}

export function sendInvitation(invitation: any): Promise<boolean> {
    const service_account_id = localStorage.getItem("service_account_id");
    return axiosInstance
        .post(
            `${process.env.REACT_APP_ACCOUNT_SERVICE_URI}/invitation/send?component_id=1&tenant_id=${service_account_id}`,
            invitation
        )
        .then((res) => (res.status == 200 ? true : false))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user', text: err.response.data.message });
            return false;
        });
}

export function validateInvitation(token: string): Promise<boolean> {
    return axiosInstance
        .get(`${process.env.REACT_APP_ACCOUNT_SERVICE_URI}/invitation/validate/${token}?component_id=1`)
        .then((res) => (res.status == 200 ? true : false))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return false;
        });
}

export function fetchUser(id: IUser["user_id"]): Promise<any> {
    const SERVICE_ACC_ID = localStorage.getItem("service_account_id");
    return axiosInstance
        .get(`${process.env.REACT_APP_ACCOUNT_SERVICE_URI}/user/${id}?component_id=1&tenant_id=${SERVICE_ACC_ID}`)
        .then((res) => (res.status == 200 ? res.data : false))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return false;
        });
}

export function deleteUser(id: IUser["user_id"]): Promise<any> {
    const SERVICE_ACC_ID = localStorage.getItem("service_account_id");
    return axiosInstance
        .delete(`${process.env.REACT_APP_ACCOUNT_SERVICE_URI}/user/${id}?component_id=1&tenant_id=${SERVICE_ACC_ID}`)
        .then((res) => (res.status == 200 ? true : false))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return false;
        });

}

export function deactivateUserCard(
    id: IUser["user_id"],
    cardId: number
): Promise<any> {
    return axiosInstance
        .post(
            `${process.env.REACT_APP_SMART_STORAGE_URI}/access_card/deactivate_card/?user_id=${id}&access_card_id=${cardId}`
        )
        .then((res) => (res.status == 200 ? true : false))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return false;
        });
}

export function deactivateAuthUserCard(
    id: IUser["user_id"],
    cardId: number
): Promise<any> {
    return axiosInstance
        .post(
            `${process.env.REACT_APP_SMART_STORAGE_URI}/access_card/deactivate_my_card/?user_id=${id}&access_card_id=${cardId}`
        )
        .then((res) => (res.status == 200 ? true : false))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return false;
        });
}

export function requestUserCard(id: IUser["user_id"]): Promise<any> {
    return axiosInstance
        .post(
            `${process.env.REACT_APP_SMART_STORAGE_URI}/access_card/user?user_id=${id}`
        )
        .then((res) => (res.status == 200 ? handlStatusCodeMessage({ code: res.status, entity: 'user' }) : false))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user', text: err.response.data.message });
            return false;
        });
}

export function fetchInvitations(
    page = 0,
    size = 10,
    sort = ""
): Promise<{ invitations?: any[]; totalInvitationsCount?: number }> {
    const SERVICE_ACC_ID = localStorage.getItem("service_account_id");
    return axiosInstance
        .get(
            `${process.env.REACT_APP_ACCOUNT_SERVICE_URI}/user?component_id=1&is_invited=true&profile_status=Pending&tenant_id=${SERVICE_ACC_ID}&page=${page}&size=${size}&sort=${sort}`
        )
        .then((res) =>
            res.status == 200
                ? {
                    invitations: res.data,
                    totalInvitationsCount: res.headers["x-total-count"],
                }
                : {
                    invitations: [],
                    totalInvitationsCount: 0,
                }
        )
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return {
                invitations: [],
                totalInvitationsCount: 0,
            };
        });
}

export function resendInvitation(id: IUser["user_id"]): Promise<any> {
    const service_account_id = localStorage.getItem("service_account_id");
    return axiosInstance
        .post(
            `${process.env.REACT_APP_ACCOUNT_SERVICE_URI}/invitation/resend?component_id=1&tenant_id=${service_account_id}&user_id=${id}`
        )
        .then((res) => (res.status == 200 ? true : false))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return false;
        });
}

export function updateUser(
    id: IUser["user_id"],
    userGroupIds: number[],
    branchIds: number[]
): Promise<any> {
    const service_account_id = localStorage.getItem("service_account_id");
    console.log(id);
    console.log(userGroupIds);
    console.log(branchIds);
    return axiosInstance
        .put(`${process.env.REACT_APP_ACCOUNT_SERVICE_URI}/user/${id}?component_id=1&tenant_id=${service_account_id}`, {
            user_group_ids_list: userGroupIds,
            branch_ids_list: branchIds,
        })
        .then((res) => (res.status == 200 ? true : false))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return false;
        });
}
