import { axiosInstance } from "../store";
import IStorage from "../dtos/IStorage";
import IReason from "../dtos/IReason";
import { handlStatusCodeMessage } from "../helpers";

export function fetchManualStorages(): Promise<IStorage[]> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/storage?page=0&size=1000&sort=&name=&type=manual`)
        .then((res) => {
            return res.status == 200 ? res.data : []
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'manual storage' });
            return []
        })
}
export function fetchAdjustmentReasons(): Promise<IReason[]> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/lookup/stock-adjustment-reason`)
        .then((res) => {
            return res.status == 200 ? res.data : []
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'manual storage' });
            return []
        })
}
export function postRequest(request: any, requestType: string): Promise<boolean> {
    return axiosInstance.post(`${process.env.REACT_APP_SMART_STORAGE_URI}/request?request_type=${requestType}`, request)
        .then((res) => {
            handlStatusCodeMessage({ code: res.status, entity: 'manual storage' });
            return res.status == 200;
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'manual storage', text: err.response.data.message });
            return false
        })
}
//requests
export function fetchMyRequests(page = 0, size = 10, sort = ''): Promise<{ myRequests?: [], totalMyRequestsCount?: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/request/composed?page=${page}&size=${size}&sort=${sort}`)
        .then((res) => res.status == 200 ? { myRequests: res.data, totalMyRequestsCount: res.headers['x-total-count'] } : {})
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'manual storage' });
            return {
                myRequests: [],
                totalMyRequestsCount: 0
            }
        })
}
export function fetchReceivedRequests(page = 0, size = 10, sort = ''): Promise<{ receivedRequests?: [], totalReceivedRequestsCount?: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/request/received?page=${page}&size=${size}&sort=${sort}`)
        .then((res) => res.status == 200 ? { receivedRequests: res.data, totalReceivedRequestsCount: res.headers['x-total-count'] } : {})
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'manual storage' });
            return {
                receivedRequests: [],
                totalReceivedRequestsCount: 0
            }
        })
}
export function fetchRequest(requestId: number): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/request/${requestId}`)
        .then((res) => res.status == 200 ? res.data : {})
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'manual storage' });
            return {}
        })
}
export function sendRequestResponse(response: any): Promise<boolean> {
    return axiosInstance.post(`${process.env.REACT_APP_SMART_STORAGE_URI}/request/response`, response)
        .then((res) => {
            handlStatusCodeMessage({ code: res.status, entity: 'manual storage' });
            return res.status == 200;
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'manual storage' });
            return false
        })
}

