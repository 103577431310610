import { axiosInstance } from "../store";
import Constants from '../constants';
import { handlStatusCodeMessage } from "../helpers";

export function fetchDataTableProductStorages(productId= 0, page = 0, size = 10, sort = ''): Promise<{ productStorages: any[], totalCountPages: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/product/${productId}/balance?page=${page}&size=${size}&sort=${sort}`)
        .then((res) => {
            return {
                productStorages: res.status === 200 ?
                    res.data.map((p: any) => { return { ...p } })
                    : []
                , totalCountPages: res.headers['x-total-count']
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'product storage' });
            return {
                productStorages: [],
                totalCountPages: 0
            }
        })
}