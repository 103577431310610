import axios from "axios";
import { axiosInstance } from "../store"
import { toast } from "react-toastify";
import IProduct from "../dtos/IProduct";

export function addToCart(product_id: number, cart_type: string, quantity?: number): Promise<any> {
  if (!quantity) quantity = 1;
  return new Promise((resolve, reject) => {
    return axiosInstance.post(`${process.env.REACT_APP_SMART_STORAGE_URI}/cart/${localStorage.getItem('branch_id')}?product_id=${product_id}&quantity=${quantity}&cart_type=${cart_type}&service_account_id=${localStorage.getItem('service_account_id')}&storefront_id=1`)
      .then((res) => {
        resolve(res.data);
      }).catch((err) => {
        reject(err);
      })
  })
}

export function getCart(cart_type: string, page = 0, size = 10): Promise<any> {
  return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/cart/${localStorage.getItem('branch_id')}?cart_type=${cart_type}&service_account_id=${localStorage.getItem('service_account_id')}&page=${page}&size=${size}&storefront_id=1`)
    .then((res) => {
      return { cartItems: res.data, totalCountCartItems: res.headers['x-total-count'] }
    })
    .catch((err) => {
      return { cartItems: [], totalCountCartItems: 0 };
    });
}

export function updateCartItem(cart_id: number, product_id: number, quantity?: number): Promise<any> {
  return new Promise((resolve, reject) => {
    return axiosInstance.put(`${process.env.REACT_APP_SMART_STORAGE_URI}/cart-item/${cart_id}/quantity?product_id=${product_id}&quantity=${quantity}&branch_id=${localStorage.getItem('branch_id')}&service_account_id=${localStorage.getItem('service_account_id')}&storefront_id=1`)
      .then((res) => {
        toast['success']("Item quantity updated successfully.");
        resolve(res.data);
      })
      .catch((err) => {
        if (err.response.status == 409) {
          toast['warning']("Item quantity not updated. You've reached max quantity for this item.");
        } else {
          toast['error']("Item quantity not updated.");
        }
        reject(err.response);
      })
  })
}

export function deleteCartItem(cart_id: number, product_id: number): Promise<any> {
  return new Promise((resolve, reject) => {
    return axiosInstance.delete(`${process.env.REACT_APP_SMART_STORAGE_URI}/cart-item/${cart_id}?&product_id=${product_id}&service_account_id=${localStorage.getItem('service_account_id')}&storefront_id=1`)
      .then((res) => {
        toast['success']("Item deleted successfully.");
        resolve(res);
        return true;
      }).catch((err) => {
        toast['error']("Error, Item not deleted.");
        reject(err);
        return false;
      })
  })
}

export function getCartProductById(product_id: number, cart_id: number): Promise<IProduct> {
  return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/service-account-catalog/${product_id}/product?&cart_id=${cart_id}&service_account_id=${localStorage.getItem('service_account_id')}&storefront_id=1`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
}

export function placeOrder(subject_id: any): Promise<any> {
  return new Promise((resolve, reject) => {
    return axiosInstance.post(`${process.env.REACT_APP_SMART_STORAGE_URI}/order/checkout?storefront_id=1&subject_id=${subject_id}`)
      .then((res) => {
        resolve(res.data);
      }).catch((err) => reject(err));
  })
}
export function placeQuotation(subject_id: any): Promise<any> {
  return new Promise((resolve, reject) => {
    return axiosInstance.post(`${process.env.REACT_APP_SMART_STORAGE_URI}/quotation/checkout?storefront_id=1&subject_id=${subject_id}`)
      .then((res) => {
        resolve(res.data);
      }).catch((err) => reject(err));
  })
}



export function getCartSummary(cart_type: string): Promise<any> {
  return new Promise((resolve, reject) => {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/cart/${localStorage.getItem('branch_id')}/summery?cart_type=${cart_type}&service_account_id=${localStorage.getItem('service_account_id')}&storefront_id=1`)
      .then((res) => resolve(res.data))
      .catch(err => reject(err.response))
  })
}