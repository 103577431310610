import { axiosInstance } from "../store";
import Constants from '../constants';
import IUserGroup from "../dtos/IUserGroup";
import { handlStatusCodeMessage } from "../helpers";
import { component } from "../containers/user-group/namings";

export function fetchUserGroups(page = 0, size = 10): Promise<{ userGroups: IUserGroup[], totalCountPages: number }> {
    const SERVICE_ACC_ID = localStorage.getItem("service_account_id");
    return axiosInstance.get(`${process.env.REACT_APP_ACCOUNT_SERVICE_URI}/user-group?tenant_id=${SERVICE_ACC_ID}&target_tenant_id=${SERVICE_ACC_ID}&component_id=1&target_component_id=1&size=${size}&page=${page}`)
        .then((res) => {
            return {
                userGroups: res.status === 200 ? res.data.map((b: any) => { return { ...b, selected: false } }) : []
                , totalCountPages: res.headers['x-total-count']
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user group' });
            return {
                userGroups: [],
                totalCountPages: 0
            }
        })
}
export function addUserGroups(userGroupData: IUserGroup): Promise<IUserGroup> {
    const SERVICE_ACC_ID = localStorage.getItem("service_account_id");
    const branch_view_permission = {
        "id": 16,
        "name": "BRANCH_VIEW",
        "module": {
            "id": 2,
            "name": "BRANCH"
        }
    };
    const userGroup = 
    {
        name: userGroupData.name,
        description: userGroupData.description,
        permissions: [...userGroupData.permissions,branch_view_permission],
        tenant_id: SERVICE_ACC_ID,
        component_id:"1"
    };
    return axiosInstance.post(`${process.env.REACT_APP_ACCOUNT_SERVICE_URI}/user-group?tenant_id=${SERVICE_ACC_ID}&component_id=1`, userGroup)
    .then((res) => res.data)
    .catch((err) => {
        handlStatusCodeMessage({ code: err.response.status, entity: 'user group' });
        return {}
    })
}
export function putUserGroups(id: string, userGroupData: IUserGroup): Promise<IUserGroup> {
    const SERVICE_ACC_ID = localStorage.getItem("service_account_id");
    return axiosInstance.put(`${process.env.REACT_APP_ACCOUNT_SERVICE_URI}/user-group/${id}?tenant_id=${SERVICE_ACC_ID}&component_id=1`, {
        id: id,
        name: userGroupData.name,
        description: userGroupData.description,
        permissions: userGroupData.permissions,
        tenant_id: SERVICE_ACC_ID,
        component_id:1
    })
    .then((res) => res.data)
    .catch((err) => {
        handlStatusCodeMessage({ code: err.response.status, entity: 'user group' });
        return {}
    })
}

export function removeUserGroups(id: string): Promise<boolean> {
    const SERVICE_ACC_ID = localStorage.getItem("service_account_id");
    return axiosInstance.delete(`${process.env.REACT_APP_ACCOUNT_SERVICE_URI}/user-group/${id}?tenant_id=${SERVICE_ACC_ID}&component_id=1`)
        .then((res) => res.status === 200)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user group' });
            return false
        })
}

export function fetchUserGroup(id: string): Promise<IUserGroup> {
    const service_account_id = localStorage.getItem("service_account_id");
    return axiosInstance.get(`${process.env.REACT_APP_ACCOUNT_SERVICE_URI}/user-group/${id}?component_id=1&tenant_id=${service_account_id}`)
        .then((res) => res.data)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user group' });
            return {}
        })
}

// export function fetchUserGroupsPermissions(): Promise<any> {
//     return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/lookup/permissions`)
//         .then((res) => res.status === 200 ? res.data : [])
//         .catch((err) => {
//             handlStatusCodeMessage({ code: err.response.status, entity: 'user group' });
//             return []
//         })
// }
export function fetchUserGroupsPermissions(componentName:component): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_ACCOUNT_SERVICE_URI}/permission?component=${componentName}`)
        .then((res) => res.status === 200 ? res.data : [])
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user group' });
            return []
        })
}