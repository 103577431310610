import { handlStatusCodeMessage } from "../helpers";
import { axiosInstance } from "../store";
const SERVICE_ACC_ID = localStorage.getItem("service_account_id");

export function fetchOrderProducts(page = 0, size = 10, sort = ''): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/order_entry?page=${page}&size=${size}&sort=${sort}`)
        .then((res) => {
            return { orderProducts: res.status === 200 ? res.data : [], totalCountOrderProducts: res.headers['x-total-count'] }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'smart ordering' });
            return {
                orderProducts: [],
                totalCountOrderProducts: 0
            }
        })
}
export function addOrderProduct(product: any): Promise<boolean> {
    return axiosInstance.post(`${process.env.REACT_APP_SMART_STORAGE_URI}/order_entry?`, product)
        .then((res) => {
            handlStatusCodeMessage({ code: res.status, entity: 'smart ordering', text: 'Product has been added succesfully' });
            return (res.status == 200)
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'smart ordering', text: err.response.data.message });
            return false;
        })

}
export function updateOrderProduct(productId: number, product: any): Promise<boolean> {
    return axiosInstance.put(`${process.env.REACT_APP_SMART_STORAGE_URI}/order_entry/${productId}?`, product)
        .then((res) => {
            if (res.status == 200) {
                return true
            }
            else {
                return false
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'smart ordering' });
            return false
        })
}
export function fetchOrdersHistory(page = 0, size = 10, sort = '', status = ''): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/order?page=${page}&size=${size}&sort=${sort}&status=${status}`)
        .then((res) => {
            return { ordersHistory: res.status === 200 ? res.data : [], totalCountOrders: res.headers['x-total-count'] }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'smart ordering' });
            return {
                ordersHistory: [],
                totalCountOrders: 0
            }
        })
}
export function fetchOrderDetails(orderId: number): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/order/${orderId}?`)
        .then((res) => {
            if (res.status == 200) {
                return res.data
            }
            else {
                return {}
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'smart ordering' });
            return {}
        })
}

export function fetchOrdersPreview(): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/order?status=Open`)
        .then((res) => {
            if (res.status == 200) {
                return res.data
            }
            else {
                return []
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'smart ordering' });
            return []
        })
}

export function fetchPaymentMethods(): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/payment_method`)
        .then((res) => {
            if (res.status == 200) {
                return res.data
            }
            else {
                return []
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'smart ordering' });
            return []
        })
}
export function purchaseOrder(orderId: number, payment: { id: number, name: string } | {}): Promise<any> {
    return axiosInstance.post(`${process.env.REACT_APP_SMART_STORAGE_URI}/order/${orderId}/checkout`, payment)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'smart ordering', text: err.response.data.message });
            return false;
        })

}

export function fetchFulfillmentsUsed(currentDate: string): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/order?status.neq=Declined&created_after=${currentDate}-01T00:00:00&is_vendor=true`)
        .then((res) => {
            if (res.status == 200) {
                console.log("fetchFulfillmentsUsed", res)
                return res.headers['x-total-count']
            }
            else {
                return 0
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'smart ordering' });
            return 0
        })
}

export function fetchFulfillmentsCount(): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/serviceaccount/${SERVICE_ACC_ID}/settings`)
        .then((res) => {
            if (res.status == 200) {
                const fulfilmentsCount = res.data.find((f: any) => f.settings.service_account_setting.includes("fulfillment"))
                return fulfilmentsCount.value
            }
            else {
                return 0
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'smart ordering' });
            return 0
        })
}