import { axiosInstance } from "../store";
import IUnit from "../dtos/IUnit";
import IStatus from "../dtos/IStatus";
import { handlStatusCodeMessage } from "../helpers";

export function fetchUnits(page = 0, size = 10, sort = '', name = '', status = ''): Promise<{ units: IUnit[], totalUnitsCount: 0 }> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/business-unit?is_active=true&page=${page}&size=${size}&sort=${sort}&name=${name}&status=${status}`)
        .then((res) => {
            if (res.status == 200) {
                return {
                    units: res.data,
                    totalUnitsCount: res.headers['x-total-count']
                }
            }
            else {
                return {
                    units: [],
                    totalUnitsCount: 0
                }
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'unit' });
            return {
                units: [],
                totalUnitsCount: 0
            }
        })
}
export function fetchUnitStatus(): Promise<IStatus[]> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/business-unit/status`)
        .then((res) => {
            if (res.status == 200) {
                return res.data
            }
            else {
                return []
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'unit' });
            return []
        })
}
export function fetchUnit(unitId: string): Promise<IUnit> {
    return axiosInstance.get(`${process.env.REACT_APP_SMART_STORAGE_URI}/business-unit/${unitId}`)
        .then((res) => {
            if (res.status == 200) {
                return res.data
            }
            else {
                return {}
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'unit' });
            return {}
        })
}
export function addUnit(unit: IUnit): Promise<boolean> {
    return axiosInstance.post(`${process.env.REACT_APP_SMART_STORAGE_URI}/business-unit`, unit)
        .then((res) => {
            handlStatusCodeMessage({ code: res.status, entity: 'unit' });
            return (res.status == 201)
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'unit' });
            return false
        })

}
export function updateUnit(unitId: string, unit: IUnit): Promise<boolean> {
    return axiosInstance.put(`${process.env.REACT_APP_SMART_STORAGE_URI}/business-unit?id=${unitId}`, unit)
        .then((res) => {
            handlStatusCodeMessage({ code: res.status, entity: 'unit' });
            return (res.status == 200)
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'unit' });
            return false
        })

}